import LandingPageComponent from "components/pages/Landing";
import { GetServerSideProps } from "next";
import toTitleCase from "utils/toTitleCase";

const DEFAULT_RETURN_VALUE = {
	props: { dynamicTitle: null },
};

const TX_COUNTIES_WE_SERVE = [
	"Bastrop",
	"Bell",
	"Bexar",
	"Blanco",
	"Brazoria",
	"Brazos",
	"Burnet",
	"Cameron",
	"Chambers",
	"Collin",
	"Comal",
	"Dallas",
	"Denton",
	"El Paso",
	"Ellis",
	"Fort Bend",
	"Galveston",
	"Grayson",
	"Guadalupe",
	"Harris",
	"Hays",
	"Hidalgo",
	"Hill",
	"Hunt",
	"Jefferson",
	"Johnson",
	"Kaufman",
	"Kendall",
	"Liberty",
	"Llano",
	"Lubbock",
	"Mclennan",
	"Montgomery",
	"Nueces",
	"Parker",
	"Potter",
	"Randall",
	"Rockwall",
	"Smith",
	"Tarrant",
	"Taylor",
	"Travis",
	"Van Zandt",
	"Waller",
	"Webb",
	"Williamson",
];

export const getDynamicTitleFromUtmTerm = (utmTerm: string): string | null => {
	for (const county of TX_COUNTIES_WE_SERVE) {
		const regex = new RegExp(county.toLowerCase(), "gi");
		if (regex.test(utmTerm)) {
			return `Reduce your ${toTitleCase(county)} County property taxes`;
		}
	}

	const prefixSearchTerms = [
		"lower",
		"contest",
		"appeal",
		"protest",
		"dispute",
	];

	for (const prefix of prefixSearchTerms) {
		const regex = new RegExp(prefix, "gi");
		if (regex.test(utmTerm)) {
			return `${toTitleCase(prefix)} your property taxes`;
		}
	}

	const reliefSearchTerm = new RegExp("property tax relief", "gi");

	if (reliefSearchTerm.test(utmTerm)) {
		return `Start saving with property tax relief`;
	}

	return null;
};

export const getServerSideProps: GetServerSideProps = async context => {
	try {
		const utmTerm = context.query?.utm_term as string | undefined;

		if (!utmTerm) {
			return DEFAULT_RETURN_VALUE;
		}

		const dynamicTitle = getDynamicTitleFromUtmTerm(utmTerm);

		return {
			props: {
				dynamicTitle,
			},
		};
	} catch (err) {
		return DEFAULT_RETURN_VALUE;
	}
};

const LandingPage = (props: any) => {
	return <LandingPageComponent dynamicTitle={props.dynamicTitle} />;
};

export default LandingPage;
